import React from 'react';
import NewsContent from './NewsContent';
import './News.css';

const News = () => {
  const newsItems = [
    // {
    //   date: "日付",
    //   text: "見出し",
    //   link: "URL"
    // },
    {
      date: "2024.10.24",
      text: "星野リゾートBEBの「いばらき絶景推しぬい旅」を監修させていただきました！",
      link: "//prtimes.jp/main/html/rd/p/000000013.000128608.html"
    },
    {
      date: "2024.04.17",
      text: "星野リゾートBEBと共同リリース！株式会社推しメーターが星野リゾートBEBと推し活ルーム共同プロデュースさせていただきました！",
      link: "//prtimes.jp/main/html/rd/p/000000007.000128608.html#:~"
    },
    {
      date: "2024.04.17",
      text: "推しメーターが新アプリ推しマップをリリース！世界中の推し活が集まる推し活教え合いアプリ「推しマップ」を株式会社推しメーターがリリースいたしました！！",
      link: "//prtimes.jp/main/html/rd/p/000000005.000128608.html#:~"
    },
    {
      date: "2023.09.15",
      text: "21歳現役大学生がプロeスポーツチームを買収！株式会社推しメーターが設立11ヶ月で世界最大規模eスポーツゲームのプロチームの運営会社に！",
      link: "//prtimes.jp/main/html/rd/p/000000001.000128608.html"
    },
    {
      date: "2022.11.28",
      text: "若手起業家向けのシードラウンド投資を行うベンチャーキャピタル「合同会社iU Z investment」の１号案件として出資していただきました!",
      link: "//prtimes.jp/main/html/rd/p/000000001.000112579.html#:~"
    },
  ];

  return (
    <div className="news-subitem">
      <div className="news-title">
        <h1 className="news-title-en">NEWS</h1>
        <p className="news-title-jp">最新のお知らせ</p>
      </div>

      {newsItems.map((item, index) => (
        <NewsContent
          key={index}
          date={item.date}
          text={item.text}
          link={item.link}
        />
      ))}

      <div className="grey-divider"></div>
    </div>
  );
}

export default News;